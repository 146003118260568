import React from "react";
import IconInfoBubble from "app/pages/.shared/IconInfoBubble";
import { FormattedMessage } from "react-intl";
import "./FullyCustomizableNote.scss";

const FullyCustomizableNote = () => {
	return (
		<div className="fully-customizable-note">
			<IconInfoBubble />
			<FormattedMessage id="product.page.negociated.note" />
		</div>
	);
};

export default React.memo(FullyCustomizableNote);
