import React from "react";
import "./horizontal-dot-loader.scss";
import PropTypes from "prop-types";

class HorizontalDotLoader extends React.PureComponent {
	render() {
		const { color = "#2B383F", dotSize = "6px" } = this.props;

		const dotStyle = {
			backgroundColor: color,
			height: dotSize,
			width: dotSize,
		};

		return (
			<div className="horizontal-dot-loader">
				<div className="horizontal-dot-loader__dot" style={dotStyle} />
				<div className="horizontal-dot-loader__dot" style={dotStyle} />
				<div className="horizontal-dot-loader__dot" style={dotStyle} />
			</div>
		);
	}
}

HorizontalDotLoader.propTypes = {
	color: PropTypes.string,
	dotSize: PropTypes.string,
};

export default HorizontalDotLoader;
