import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import HelpDeskPhoneNumberContainer from "app/pages/.shared/HelpDeskPhoneNumber/HelpDeskPhoneNumberContainer.jsx";
import FooterLogos from "app/pages/Footer/FooterLogos/FooterLogos";
import ShopSwitcherContainer from "app/pages/Home/ShopSwitcher/ShopSwitcherContainer";
import SocialNetworksList from "app/pages/.shared/SocialNetworksList/SocialNetworksList";
import { LanguageSwitcher } from "app/pages/.shared/LanguageSwitcher/LanguageSwitcher";
import {
	landingPageAuthShape,
	partnerRedirectionLinkShape,
	socialNetworksLinksShape,
} from "app/utils/propTypes";
import HelpLink from "app/pages/Footer/HelpLink/HelpLink";
import NoteCasinoContainer from "app/pages/Footer/NoteCasino/NoteCasinoContainer";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";
import "./Footer.scss";
import ReassurancePayment from "app/pages/.shared/ReassurancePayment/ReassurancePayment";
import Separator from "app/pages/.shared/Separator";
import Reassurance from "app/pages/Footer/Reassurance/Reassurance";
import IconEngagementPaiementSecurise from "app/pages/.shared/static/icons/IconEngagementPaiementSecurise";
import RelativeLink from "app/pages/.shared/RelativeLink";

const Footer = ({
	partnerRedirectionLink,
	socialNetworksLinks,
	shop,
	cgvDocumentName,
	legalesDocumentName,
	confidentialiteDocumentName,
	landingPageAuth,
	showReassurance,
	showShopSwitcher,
	showPfsMessage,
	useSVA,
	additionalNote,
	feedbackLink,
	showCasinoNote,
	resolution,
	isHomePage,
	displayPhone,
	showConfigureCookieLink,
	showReassurancePayment,
	paymentMethods,
	additionalLinks,
	brandDisplayName,
}) => {
	const isMobile = resolution === RESOLUTION.SMALL || resolution === RESOLUTION.MEDIUM;
	const showSocialNetworksLinks =
		socialNetworksLinks && (socialNetworksLinks.instagram || socialNetworksLinks.facebook);

	return (
		<footer className="footer">
			<div className="footer__container">
				{/*Ne pas afficher les engagagement dans le footer de la Home*/}
				{!isHomePage && showReassurance && (
					<div className="footer__engagements">
						<Reassurance paymentMethods={paymentMethods} />
						<hr className="footer__separator" />
					</div>
				)}
				<div className="footer__footer">
					<div className="footer__social">
						{showReassurancePayment && (
							<React.Fragment>
								<div className="footer__reassurance-payment-headline">
									<IconEngagementPaiementSecurise width={35} height={35} />
									<div className="footer__reassurance-payment-label">
										<FormattedMessage id="general.engagement.4" />
									</div>
								</div>
								<ReassurancePayment paymentMethods={paymentMethods} />
								<Separator />
							</React.Fragment>
						)}
						<div className="social">
							{showPfsMessage && (
								<React.Fragment>
									<div className="footer__partner">
										<div className="footer__partner-title">
											<FormattedMessage id="footer.partner.title" />
										</div>
										<div className="footer__partner-text">
											<FormattedMessage id="footer.partner.text" />
										</div>

										<div className="footer__partner-logo">
											<i className="icon icon--logo-perfectstay-white" />
										</div>
									</div>
									<hr className="footer__separator" />
								</React.Fragment>
							)}
							{showSocialNetworksLinks && (
								<SocialNetworksList socialNetworksLinks={socialNetworksLinks} />
							)}
						</div>
					</div>

					{showSocialNetworksLinks && isMobile && <hr className="footer__separator" />}

					<div className="footer__contact">
						<React.Fragment>
							<div className="footer-contact">
								<div className="footer-contact__headline">
									<FormattedMessage id="footer.contact.headline" />
								</div>
								<HelpDeskPhoneNumberContainer useSVA={useSVA} />
							</div>
							<hr className="footer__separator" />
						</React.Fragment>

						{showShopSwitcher && (
							<div className="footer__language">
								<ShopSwitcherContainer
									renderComponent={props => <LanguageSwitcher {...props} />}
								/>
							</div>
						)}
					</div>
					<div className="footer__menu">
						<div className="footer__row">
							<ul className="footer-menu">
								{partnerRedirectionLink &&
									partnerRedirectionLink.url &&
									partnerRedirectionLink.label && (
										<li className="footer-menu__item">
											<a
												className="relative-link"
												rel="noopener noreferrer"
												target="_blank"
												href={partnerRedirectionLink.url}
											>
												{partnerRedirectionLink.label}
											</a>
										</li>
									)}
								{feedbackLink && brandDisplayName && (
									<li className="footer-menu__item">
										<a
											className="relative-link"
											rel="noopener noreferrer"
											target="_blank"
											href={feedbackLink}
										>
											<FormattedMessage
												id="footer.feedback.label"
												values={{ brandName: brandDisplayName }}
											/>
										</a>
									</li>
								)}
								{cgvDocumentName && (
									<li className="footer-menu__item">
										<a
											className="relative-link"
											rel="noopener noreferrer"
											target="_blank"
											href={cgvDocumentName}
										>
											<FormattedMessage id="menu.item.cgv" />
										</a>
									</li>
								)}
								{legalesDocumentName && (
									<li className="footer-menu__item">
										<a
											className="relative-link"
											rel="noopener noreferrer"
											target="_blank"
											href={legalesDocumentName}
										>
											<FormattedMessage id="menu.item.legales" />
										</a>
									</li>
								)}
								{confidentialiteDocumentName && (
									<li className="footer-menu__item">
										<a
											className="relative-link"
											rel="noopener noreferrer"
											target="_blank"
											href={confidentialiteDocumentName}
										>
											<FormattedMessage id="menu.item.confidentialite" />
										</a>
									</li>
								)}

								{showConfigureCookieLink && (
									<li className="footer-menu__item">
										<button
											type="button"
											id="ot-sdk-btn"
											className="relative-link ot-sdk-show-settings"
										>
											<FormattedMessage id="menu.item.cookies" />
										</button>
									</li>
								)}
							</ul>
							<ul className="footer-menu">
								<li className="footer-menu__item">
									<HelpLink />
								</li>

								{additionalLinks.map(link => (
									<li key={link.name} className="footer-menu__item">
										<RelativeLink
											data-testid={`additional-link-${link.name}`}
											rel="noopener noreferrer"
											target={link.openInNewWindow ? "_blank" : "_self"}
											to={{ pathname: link.URL }}
										>
											{link.name}
										</RelativeLink>
									</li>
								))}
							</ul>
						</div>

						<div className="footer__row">
							<div className="footer-pictos">
								<hr className="footer__separator" />
								<FooterLogos shop={shop} />
							</div>
						</div>
					</div>
				</div>

				{additionalNote && <div className="footer__note">{additionalNote}</div>}

				{showCasinoNote && (
					<div className="footer__note">
						<NoteCasinoContainer />
					</div>
				)}

				{landingPageAuth.note && <div className="footer__note">{landingPageAuth.note}</div>}
			</div>
		</footer>
	);
};

Footer.propTypes = {
	partnerRedirectionLink: partnerRedirectionLinkShape,
	socialNetworksLinks: socialNetworksLinksShape,
	partnerRedirectionUrlFooter: PropTypes.string,
	showConfigureCookieLink: PropTypes.bool,
	cgvDocumentName: PropTypes.string,
	legalesDocumentName: PropTypes.string,
	shop: PropTypes.string,
	confidentialiteDocumentName: PropTypes.string,
	landingPageAuth: landingPageAuthShape,
	hasCasinoNote: PropTypes.bool,
	showReassurance: PropTypes.bool,
	showShopSwitcher: PropTypes.bool,
	useSVA: PropTypes.bool,
	showPfsMessage: PropTypes.bool,
	additionalNote: PropTypes.string,
	feedbackLink: PropTypes.string,
	isHomePage: PropTypes.bool,
	showCasinoNote: PropTypes.bool,
	showReassurancePayment: PropTypes.bool,
	resolution: PropTypes.string,
	displayPhone: PropTypes.arrayOf(PropTypes.string),
	paymentMethods: PropTypes.array,
	additionalLinks: PropTypes.array,
	brandDisplayName: PropTypes.string,
};

Footer.defaultProps = {
	landingPageAuth: {},
	additionalNote: null,
	useSVA: false,
	showReassurance: true,
	showPfsMessage: false,
	showShopSwitcher: false,
	paymentMethods: [],
	additionalLinks: [],
};

export default React.memo(Footer);
