import React from "react";
import PropTypes from "prop-types";
import Hamburger from "app/pages/Header/Hamburger/Hamburger";
import IconAccount from "app/pages/.shared/IconAccount";
import "./HamburgerAccount.scss";

const HamburgerAccount = ({ isActive, onToogle }) => {
	return (
		<div className="hamburger-account" data-cy="header-account-link" onClick={onToogle}>
			<Hamburger isActive={isActive} onToogle={onToogle} />
			<IconAccount />
		</div>
	);
};

HamburgerAccount.propTypes = {
	isActive: PropTypes.bool,
	onToogle: PropTypes.func,
};

export default React.memo(HamburgerAccount);
