import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import "./duration-label.scss";

class DurationLabel extends React.PureComponent {
	render() {
		const { duration, prefix } = this.props;

		return duration > 0 ? (
			<div className="duration-label">
				{prefix && <span className="duration-label__prefix">{prefix}</span>}
				<FormattedPlural
					value={duration}
					one={
						<FormattedMessage
							values={{
								duration: duration,
							}}
							id="general.duration.singular"
						/>
					}
					other={
						<FormattedMessage
							values={{
								duration: duration,
							}}
							id="general.duration.plural"
						/>
					}
				/>
			</div>
		) : (
			false
		);
	}
}

DurationLabel.propTypes = {
	duration: PropTypes.number,
	prefix: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default DurationLabel;
