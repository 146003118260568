import PropTypes from "prop-types";
import React from "react";
import AmountContainer from "app/pages/.shared/AmountContainer";
import Separator from "app/pages/.shared/Separator";
import MoreDetails from "app/pages/.shared/MoreDetails";
import { FormattedDate, FormattedMessage } from "react-intl";
import "./echeancier.scss";

class Echeancier extends React.PureComponent {
	render() {
		const {
			apport,
			mensualite,
			coutCredit,
			prixVente,
			paymentPartner,
			mensualiteCount,
			instruction,
		} = this.props;

		const today = new Date();

		const datePrelevement2 = new Date(
			today.getFullYear(),
			today.getMonth() + 1,
			today.getDate()
		);
		const datePrelevement3 = new Date(
			today.getFullYear(),
			today.getMonth() + 2,
			today.getDate()
		);
		const datePrelevement4 = new Date(
			today.getFullYear(),
			today.getMonth() + 3,
			today.getDate()
		);

		return (
			<div className="echeancier">
				<div className="echeancier__details">
					<div className="devis">
						<div className="devis__intro">
							<FormattedMessage
								id="echeancier.devis.intro"
								values={{ paymentPartner: paymentPartner }}
							/>
						</div>
						<div className="devis__item">
							<div className="devis__label">
								<FormattedMessage id="echeancier.price.label" />
							</div>
							<div className="devis__amount">
								<AmountContainer amount={prixVente} />
							</div>
						</div>
						<div className="devis__item">
							<div className="devis__label">
								<FormattedMessage
									id="echeancier.credit.cost.label"
									values={{ count: mensualiteCount }}
								/>
							</div>
							<div className="devis__amount">
								<AmountContainer amount={coutCredit} />
							</div>
						</div>
						<div className="devis__item">
							<div className="devis__label">
								<FormattedMessage id="echeancier.total.label" />
							</div>
							<div className="devis__amount">
								<AmountContainer amount={prixVente + coutCredit} />
							</div>
						</div>
						<div className="devis__item">
							<div className="devis__label">
								<FormattedMessage
									id="echeancier.mensualite.1.label.with.amount"
									values={{
										apport: (
											<span>
												<AmountContainer amount={apport} />
											</span>
										),
									}}
								/>
							</div>
						</div>
					</div>

					<MoreDetails
						expandedLabel={
							<FormattedMessage id="echeancier.more.details.expanded.label" />
						}
						collapsedLabel={<FormattedMessage id="echeancier.more.details.label" />}
					>
						<Separator design="middle" />
						<div className="mensualites">
							<div className="mensualites__intro">
								<FormattedMessage
									id="echeancier.intro"
									values={{ count: mensualiteCount }}
								/>
							</div>
							<div className="mensualites__item">
								<div className="mensualites__label">
									<FormattedMessage id="echeancier.mensualite.1.label" />
								</div>
								<div className="mensualites__amount">
									<AmountContainer amount={apport} />
								</div>
							</div>
							<div className="mensualites__item">
								<div className="mensualites__label">
									<FormattedMessage
										id="echeancier.mensualite.2.label"
										values={{
											date: (
												<span className="mensualites__date">
													<FormattedDate
														value={datePrelevement2}
														day="2-digit"
														month="2-digit"
														year="numeric"
													/>
												</span>
											),
										}}
									/>
								</div>
								<div className="mensualites__amount">
									<AmountContainer amount={mensualite} />
								</div>
							</div>
							<div className="mensualites__item">
								<div className="mensualites__label">
									<FormattedMessage
										id="echeancier.mensualite.3.label"
										values={{
											date: (
												<span className="mensualites__date">
													<FormattedDate
														value={datePrelevement3}
														day="2-digit"
														month="2-digit"
														year="numeric"
													/>
												</span>
											),
										}}
									/>
								</div>
								<div className="mensualites__amount">
									<AmountContainer amount={mensualite} />
								</div>
							</div>
							<div className="mensualites__item">
								<div className="mensualites__label">
									<FormattedMessage
										id="echeancier.mensualite.4.label"
										values={{
											date: (
												<span className="mensualites__date">
													<FormattedDate
														value={datePrelevement4}
														day="2-digit"
														month="2-digit"
														year="numeric"
													/>
												</span>
											),
										}}
									/>
								</div>
								<div className="mensualites__amount">
									<AmountContainer amount={mensualite} />
								</div>
							</div>
						</div>
					</MoreDetails>
				</div>
				{instruction ? (
					<div className="echeancier__instruction">
						<Separator design="full" />
						{instruction}
					</div>
				) : (
					false
				)}
			</div>
		);
	}
}

Echeancier.propTypes = {
	instruction: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	paymentPartner: PropTypes.string,
	mensualiteCount: PropTypes.number,
	apport: PropTypes.number,
	mensualite: PropTypes.number,
	coutCredit: PropTypes.number,
	prixVente: PropTypes.number,
};

export default Echeancier;
